<template>
  <div class="container" v-if="detail" >
    <breadcrumb :current="{ title: detail.title }"></breadcrumb>
    <main-info :title="detail.title">
      <img slot="img" :src="detail.titleImg" alt="">
      <div slot="content">
        <!-- <p>项目编号:{{ detail.projectCode }}</p>
        <p>类别: <dict-tag :type="dict.category" :value="detail.category" /></p>
        <p>级别:<dict-tag :type="dict.level" :value="detail.level" /></p>
        <p>批次:<dict-tag :type="dict.batch" :value="detail.batch" /></p> -->
        <p>报名时间:{{ formatDate(detail.startTime, 'yyyy年MM月dd日') }} - {{ formatDate(detail.endTime, 'yyyy年MM月dd日') }}</p>
        <p>主办方:{{ detail.sponsor }}</p>
        <p>活动地点:{{ detail.address }}</p>
        <p>联系电话:{{ detail.phone }}</p>
        <p>报名名额:<span>{{ detail.quota }} / {{  detail.total }}</span></p>
        <div style="text-align: right;">
          <span  class="status-btn" style="">{{ detail.showStatus===1?'活动即将开始':detail.showStatus===2?'活动已结束':detail.showStatus===3?'正在报名...':'名额已满' }}</span>
          </div>
      </div>
    </main-info>
    <!-- 选项卡 -->
    <category isDetail @change="changeCategory" :categorys="categorys"></category>
    <!-- project -->
    <txt-view v-if="categoryIndex === 0" class="txt" :txt="detail.txt"></txt-view>
    <div v-else-if="categoryIndex === 1">
      <!-- <el-button type="success" size="small" @click="seeMap">打开地图</el-button> -->
      <TMmap ref="map"  :position="detail"></TMmap>
    </div>
    <comment v-else :id="$route.params.id" :contentType="$route.meta.contentType" />
    <recommend url="activity" :id="$route.params.id"   :contentType="$route.meta.contentType"/>
  </div>
</template>

<script>
import { getDetail,getDict} from "@/api/order/order";
// 主信息插槽
import MainInfo from "@/components/MainInfo";
import DictTag from '@/components/DictTag'
import Recommend from '@/components/RelatedRecommend'
import TMmap from "@/components/TMmap"
  export default {
    
    name:'projectDetail',
    components: {
      MainInfo,DictTag,Recommend,TMmap
    },
    watch: {
     $route(){
      this.getData()

     } 
    },
    data() {
      return {
        list: {},
	       mapShow:false ,
        detail:null,
        dict:{},  
        categoryIndex: 0,//分类下标
        categorys: [{
                name: '非遗详情',
                newsType: 1
            }, {
                name: '交通指南',
                newsType: 2
            },{
                name: '评论',
                newsType: 2
            }],
      }
    },
    created () {
      this.getData()
      // this.getDictList()
    },
    methods: {
  //     seeMap() {
	//   this.list.lng = 116.404
  //     this.list.lat = 39.915
  //     this.mapShow = true
  //     // 加载完成调用
  //     this.$nextTick(()=>{
  //       this.$refs.map.MapResetting()
  //     })
	// },
	// 地图搜索
    // MapSearch() {
    //   this.$refs.map.MapSearch('故宫')
    // }, 
      changeCategory(index) {
            this.categoryIndex = index
            // this.getList()
        },
      getData() {
        getDetail({id: this.$route.params.id}).then(res=>{
          
            if (!res.data.timeLimit) {
								//报名中

							} else {
								let nowDate = +new Date()
							
								if (res.data.startTime > nowDate) {
									// 即将开始
									res.data.showStatus = 1;

								} else if (res.data.endTime < nowDate) {

									//已结束
									res.data.showStatus = 2;
								} else {

									//正在报名
									if (res.data.quotaLimit) {

										// 判断票数
										if (res.data.quota === 0) {
											// 无票
											res.data.showStatus = 4;
										} else {
											// 报名中
											res.data.showStatus = 3;
										}
									} else {
										// 永远报名中
										res.data.showStatus = 3;
									}

								}

							}
          this.detail=res.data
        })
      },
      getDictList() {
            getDict('ich_inheritor_batch').then(res => { this.dict.batch = res.data })
            getDict('ich_inheritor_category').then(res => { this.dict.category = res.data })
            getDict('ich_inheritor_level').then(res => { this.dict.level = res.data })
        },
    },

  }
</script>

<style lang="scss" scoped>
.container {
  width: 1200px;
    margin: 0 auto;
    .status-btn {
      display: inline-block;padding: 10px 20px;background-color:#E85984;color: #fff;border-radius: 10px;
    }

}
</style>