import request from '@/utils/request'

export function getData(query) {
  return request({
      url: '/ich/activity/page',
      method: 'get',
      params: query
  })
}
export function getDict(){
  return request({
    url:'/system/dict-data/ich_activity_type',
    method: 'get',
})
}
// 获取详情
export function getDetail(query){
  return request({
    url:'/ich/activity/get',
    method: 'get',
    params: query
})
}